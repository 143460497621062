.add-detail {
    position: relative;
    .add-detail-btn {
        width: 100%;
        background: red;
        position: absolute;
        left: 0;
        bottom: 0;
        border-top: 1px solid #e9e9e9;
    }
}

.comm-stay-lists {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    .comm-stay-btns {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: -25px;
    }
}